<template>
  <ul class="trace" v-if="trace">
    <li class="item addr no-line">
      <van-icon name="location" />
      <div class="content">收货地址：{{ address }}</div>
    </li>
    <li
      v-for="(item, i) in trace"
      :key="i"
      class="item"
      :class="{ done: item.statusid === 100 }"
    >
      <i class="dotted" />
      <van-icon name="checked" />
      <div class="hd">
        <span class="tit">{{ item.type }}</span>
        {{ item.timestamp | formatDate }}
      </div>
      <div class="content">{{ item.statext }}</div>
    </li>
    <li class="item no-line">
      <van-icon name="bill" />
      <div class="hd">
        <span class="tit">已下单</span>
        {{ trace[0].timestamp | formatDate }}
      </div>
      <div class="content">商品已下单付款</div>
    </li>
  </ul>
</template>


<script>
export default {
  name: "Trace",
  data() {
    return {
      trace: null,
      address: "详细地址可能会很长很长很长很长很长很长很长很长很长很长",
    };
  },
  created() {

    this.getTrace(this.$route.params.oid);
  },
  methods: {
    getTrace(oid) {

      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      setTimeout(() => {
        this.$toast.clear();
        this.trace = [
          {
            timestamp: 1629970612,
            statext:
              "当前快递的状态，可能很长很长很长很长很长很长很长很长很长很长很长很长很长",
            statusid: 10,
            type: "已发货",
          },
          {
            timestamp: 1629980612,
            statext: "当前快递的状态，可能很长",
            statusid: 11,
            type: "已揽件",
          },
          {
            timestamp: 1629990612,
            statext: "当前快递的状态，可能很长很长很长很长很长",
            statusid: 12,
            type: "运输中",
          },
          {
            timestamp: 1630000612,
            statext: "当前快递的状态，可能很长",
            statusid: 12,
            type: "运输中",
          },
          {
            timestamp: 1630070612,
            statext: "当前快递的状态，可能很长",
            statusid: 12,
            type: "运输中",
          },
          {
            timestamp: 1630090612,
            statext: "当前快递的状态，可能很长",
            statusid: 12,
            type: "运输中",
          },
          {
            timestamp: 1630120612,
            statext:
              "当前快递的状态，可能很长很长很长很长很长很长很长很长很长很长很长很长很长",
            statusid: 100,
            type: "已签收",
          },
        ];
        this.trace = this.trace.reverse();
      }, 300);
    },
  },
};
</script>